<template>
	<div class="margin-t-20 width-fill">
		<div v-loading="loading" :class="themeClass" class="width-fill bg-fff my-account">
			<div class="title font-size18 font-weight700" v-if="getUserInfo.fCommerceTypeID == 2">交易通银行账户</div>
			<div class="font-size12 bgc-pink font-color-FF9900 width-fill flex-row" v-if="getUserInfo.fCommerceTypeID == 2"><i
				 class="iconfont flex-row-center-center margin-r-5 icon-tishi5 font-size18"></i>提示:
				交易通银行账户是为交易通钱包进行提现操作的现金接收账户，绑定后可在提现时提现至绑定的的银行账户，仅限绑定一个账户。</div>
			<div class="title font-size18 font-weight700" v-if="getUserInfo.fCommerceTypeID == 1">交易银行卡</div>

			<div class="content flex-row-wrap">
				<div class="bottom  margin-t-15" v-for="(item,index) of rechargeACout" :key="index">
					<div class="card radius10">
						<div class="card-top thcls">
							<div class="bank flex-row">
								<div class="bank-icon flex-row-center-center">
									<svg-icon icon-class="UnionPay" style="height: 30px;" />
								</div>
								<div class="bank-name font-size14  margin-r-5 font-weight700">{{item.fBankName}}</div>
								<!-- <div class="card-type text-center font-size12 background-color-theme spantype" :class="{'fzh':item.type=='充值账户'}">{{item.type}}</div> -->
								<!-- <div v-if="getUserInfo.fCommerceTypeID==2" class="unbundling1 pointer fonrc1" @click="ChangeOfAccount(item.fAccountID)">变更</div> -->
								<div v-if='!item.active' class="unbundling font-color-fff pointer" @click="unbundling(item.fAccountID)">解绑</div>
								<div v-if="item.active && fCanDelete==0" class="unbundling font-color-fff pointer fonrc" @click="goValidate(item.fAccountID)">激活账户</div>
								<div v-if="item.active && fCanDelete==1" class="unbundling font-color-fff pointer fonrc2" @click="deleteBindingRecord(item)">删除</div>
							</div>
							<div class="cardID font-color-fff font-size18 font-weight700" v-html="$options.filters.BankCardFormat(item.fAccountNumber)"></div>
							<div v-if="!item.active" class="font-size12 margin-t-10 " :class="{'font-color-fff':item.fIsDefault==0,'fonth':item.fIsDefault==1}"><span
								 class="pointer" @click="setMos(item.fAccountID)">{{item.fIsDefault==0?'设为默认':'默认'}}</span></div>
							<!-- <img src="../../../assets/u298.png"></img> -->
						</div>
					</div>
				</div>
				<!-- 展示提现账户 无数据时候 -->
				
				<!-- 企业一个卡 仅有提现 -->
				<!-- v-if="!hasactivation" -->
				<div class="bottom  margin-t-15 " v-if="rechargeACout.length == 0">
					<div class="card radius10 u665">
						<div class="card-top font-weight700 colrm pointer" @click="dialogBindingVisible = true">+添加账户</div>
					</div>
				</div>


			</div>
		</div>
		<el-dialog title="激活账户" :visible.sync="dialogFormVisible" width="25%" :close-on-click-modal="false">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm">
				<el-form-item label="激活金额" :label-width="formLabelWidth" prop="money">
					<el-input v-model.trim="ruleForm.money" autocomplete="off" placeholder="请输入来账金额激活" :required="true"></el-input>
					<p style="color: #FF9900; line-height: 16px;">
						*需登录该账户企业网银查询招商银行小额鉴权入账金额(入账金额通常小于1元)，录入此金额进行账户激活
					</p>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button :disabled="moneyloading" @click="validateCancel('ruleForm')" :loading='moneyloading'>取 消</el-button>
				<el-button :disabled="moneyloading" type="primary" @click="validateConfirm('ruleForm')" :loading='moneyloading'>确 定</el-button>
			</div>
		</el-dialog>
		<el-dialog
			:close-on-click-modal="false"
			title="绑定银行账户" :visible.sync="dialogBindingVisible" width="50%">
			
			<el-form :model="bindingform" :rules="bindingRules" ref="bindingform" label-width="130px">
				<el-form-item label="开户行名称" prop="fBankTypeCode">
					<el-select v-model="bindingform.fBankTypeCode" style="width: 100%;" filterable placeholder="可输入银行简称模糊搜索">
					    <el-option
					      v-for="item in bankList"
					      :key="item.fBankTypeCode"
					      :label="item.fBankTypeName"
					      :value="item.fBankTypeCode">
					    </el-option>
					  </el-select>
					  <p style="font-size: 12px; color: #FF9900; line-height: 16px; padding-right: 35px;">*可输入银行简称模糊搜索</p>
					  <p style="font-size: 12px; color: #FF9900; line-height: 16px; padding-right: 35px;">*未找到银行信息，请联系聚材通客服</p>
				</el-form-item>
				<el-form-item label="银行账号" prop="fAccount">
					<el-input :maxlength='30' v-model.trim="bindingform.fAccount" onkeyup="this.value=this.value.replace(/\s*/g,'')" placeholder="请输入本企业银行账号"></el-input>
					<p style="font-size: 12px; color: #FF9900; line-height: 16px; padding-right: 35px;">*仅限同名账户绑定</p>
				</el-form-item>
				<el-form-item label="开户行行号" prop="fBankCode">
					<el-input :maxlength='14' onkeyup="this.value=this.value.replace(/[^\d]/g,'')" v-model.trim="bindingform.fBankCode" placeholder="请输入绑定开户行行号"></el-input>
				</el-form-item>
				<el-form-item label="开户行地址" prop="fAddress">
					<el-input :maxlength='30' v-model.trim="bindingform.fAddress" placeholder="请输入开户行地址"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="bindingCancel('bindingform')" :disabled="buttonloading" :loading='buttonloading'>取 消</el-button>
				<el-button type="primary" @click="bindingConfirm('bindingform')" :disabled="buttonloading" :loading='buttonloading'>确认提交</el-button>
			</div>
		</el-dialog>
	</div>

</template>

<script>
	import {
		mapGetters
	} from "vuex";
	import pagination from "@/components/pagination/pagination.vue";
	export default {
		components: {
			pagination
		},
		data() {
			var checkMoney = (rule, value, callback) => {
				if (!/^(?!0+(?:\.0+)?$)(?:[1-9]\d*|0)(?:\.\d{1,2})?$/.test(value)) {
					callback(new Error("金额必须为数字值且最多保留两位小数"));
				} else {
					callback();
				}
			};
			// 银行账户范围校验
			let AccountMax = (rule, value, callback) => {
				console.log(value)
				if (value >= 6 && value <= 20) {
					// 合法的手机号
					return callback();
				}
				// 不合法
				callback(new Error("银行账户长度只能是6~20位"));
			};
			// 开户行行号范围校验
			let BankCodeMax = (rule, value, callback) => {
				console.log(value)
				if (value >= 6 && value <= 20) {
					// 合法的手机号
					return callback();
				}
				// 不合法
				callback(new Error("开户行行号长度只能是6~20位"));
			};
			return {
				searchList: [],
				showSearchList: false,
				totalCard: 0,
				fCanDelete: false,
				bankImg: '',
				bankName: '',
				fCardMediaTypeName: '',
				fCardMediaType: '',
				message: '',
				bindingform: {
					fAccount: '', // 绑定账户
					fBankTypeCode: null,
					fBankTypeName: null,
					fBankCode: '', // 绑定开户行行号
 					fAddress: '', // 绑定账户行地址
					// fIsInnerBank: 0, // 是否招行
				},
				bindingRules: {
					fAccount: [{
						required: true,
						message: "请输入银行账号",
						trigger: "blur",
					},
					{
						validator: (rule, value, callback) => {
							console.log(value)
							let value1 = value.replace(/\s*/g,'')
							const dateRegex = /^\d{6,30}$/;
							if (!dateRegex.test(value1)) {
								callback(new Error('银行账号长度只能是6~30位的数字'));
							} else {
								callback();
							}
						},
						trigger: 'blur'
					},
					],
					fBankTypeCode: [{
						required: true,
						message: "请选择开户行",
						trigger: "blur",
					}],
					fBankTypeName: [{
						required: true,
						message: "请输入开户行",
						trigger: "blur",
					}],
					fBankCode: [{
						required: true,
						message: "请输入开户行行号",
						trigger: "blur",
					},
					{
						min: 6,
						max: 14,
						message: '开户行行号长度只能是6~14位', 
						trigger: 'blur',
					}],
					fAddress: [{
						required: true,
						message: "请输入账户行地址",
						trigger: "blur",
					},
					{
						max: 30,
						message: '开户行地址不能超过30个字符', 
						trigger: 'blur',
					}
					],
				},
				moneyloading: false,
				buttonloading: false,
				dialogBindingVisible: false,
				hasactivation: false,
				typeId: 1, // 切换银行卡类型id
				fAccountID: '',
				formLabelWidth: "80px",
				dialogFormVisible: false,
				loading: false,
				rechargeACout: [],
				ruleForm: {
					money: null,
				},
				rules: {
					money: [{
						validator: checkMoney,
						trigger: "blur"
					}],
				},
				
				bankList: [],

			};
		},
		computed: {
			...mapGetters(['getThemeName']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
			...mapGetters(['getUserInfo']),
		},
		mounted() {
			this.getAcsPhysical()
			this.getBankName()
		},
		methods: {
			filterOptions(searchVal) {
			  // 根据搜索值过滤选项  
			  this.searchList = this.bankList.filter(option => option.fBankTypeName.includes(searchVal));  
			},  
			//搜索框事件
			getBankName () {
				this.ApiRequestPostNOMess('/api/mall/ebbase/bank-type/get-jyt-banktype', {
				  filter: '',
				  // skipCount: 0,
				  // maxResultCount: 0,
				  sorting: ''
				}).then(
				  result => {
					this.bankList = result.obj.items;
				
				  },
				  rej => { }
				);
			},
			//   获取开户行下拉框数据
			bindingConfirm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						// this.dialogBindingVisible = false
						if(!this.bindingform.fBankTypeCode) {
							this.$message.warning('请选择正确的开户行名称')
							return
						}
						this.buttonloading = true
						this.ApiRequestPost(
							"/api/mall/ebcustomer/bank-account/bindling-jyt-account", {
								fBankTypeCode: this.bindingform.fBankTypeCode,
								fAccountNumber: this.bindingform.fAccount,
								fBankCode: this.bindingform.fBankCode,
								fAddress: this.bindingform.fAddress,
							}
						).then(
							(result) => {
								if (result.obj.status != 0) {
									this.$message.success(result.obj.message)
									this.buttonloading = false
									this.bindingCancel(formName)
									this.getAcsPhysical()
								} else {
									this.$message.error(result.obj.message)
									this.buttonloading = false
								}
							},
							(rej) => {this.buttonloading = false}
						);
						
					} else {
						return false;
					}
				});
			},
			// 验证弹框点击取消
			bindingCancel(formName) {
				this.$refs[formName].resetFields();
				this.dialogBindingVisible = false;
			},
			// 设置默认
			setMos(id) {
				this.ApiRequestPostNOMess( '/api/mall/ebcustomer/bank-account/set-jyt-withdraw-account-default', {
						fAccountID: id
					}
				).then(
					(result) => {
						this.getAcsPhysical();
					},
					(rej) => {}
				);
			},
			// 解绑
			unbundling(id) {
				console.log(this.totalCard)
				// if(this.totalCard > 1) {
					this.$confirm('每个账户只能绑定10张银行卡，请谨慎操作。您确定解绑此银行卡吗?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.ApiRequestPost(
							"/api/mall/ebcustomer/bank-account/unbindling-jyt-account", {
								fAccountID: id
							}
						).then(
							(result) => {
								if (result.obj.status == 1) {
									this.$message.success(result.obj.message)
								} else {
									this.$message.error(result.obj.message)
								}
								this.getAcsPhysical();
						
							},
							(rej) => {
								this.getAcsPhysical();
							}
						);
						
					}).catch(() => {
						this.getAcsPhysical();
					});
				// } else {
				// 	this.$message.warning('剩余一张绑定银行卡，不可解绑')
				// }
				


			},
			// 验证弹框点击确定
			validateConfirm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.moneyloading = true
						this.ApiRequestPost(
							"/api/mall/ebcustomer/bank-account/validate-jyt-account", {
								fMoney: Number(this.ruleForm.money),
								fAccountID: this.fAccountID
							}
						).then(
							(result) => {
								if (result.obj.status == 1) {
									this.$message.success(result.message)
								} else {
									this.$message.error(result.message)
								}
								this.validateCancel(formName)
								this.getAcsPhysical()
								this.moneyloading = false
							},
							(rej) => {this.moneyloading = false}
						);
					} else {
						this.moneyloading = false
						return false;
					}
				});
			},
			// 验证弹框点击取消
			validateCancel(formName) {
				this.$refs[formName].resetFields();
				this.dialogFormVisible = false;
			},
			// 打开激活页面
			goValidate(id) {
				this.fAccountID = id;
				this.dialogFormVisible = true;
			},
			//判断交易通是否有需要激活的卡
			isActivateAcsB2bAccount() {
				this.ApiRequestPost(
					"/api/mall/ebcustomer/bank-account/is-activate-jyt-account"

				).then(
					(result) => {
						if (result.obj) {
							this.hasactivation = true
							result.obj.type = '提现账户';
							result.obj.activate = true;
							result.obj.active = true
							this.rechargeACout.push(result.obj);
						} else {
							this.hasactivation = false
						}
					},
					(rej) => {}
				);
			},

			//获取个人卡
			getAcsPhysical() {
				this.loading = true;
				let url;
				this.ApiRequestPostNOMess('api/mall/ebcustomer/bank-account/get-jyt-physical-account-list').then(
					(result) => {
						this.rechargeACout = [];
						let arrList = [];
						result.obj.forEach(item => {
							item.active = false
						})
						this.rechargeACout = result.obj;
						this.totalCard = result.obj.length
						this.isActivateAcsB2bAccount()
						this.loading = false;
					},
					(rej) => {this.loading = false;}
				);
			},
			//企业变更账服通提现账户
			ChangeOfAccount(e) {
				this.$router.push({
					path: '/businessme/enterpriseUsersBindingCard',
					query: {
						title: '提现账户信息变更',
						type: '3',
						fAccountname: '交易通',
						fAccount: e
					}
				}) //跳转企业用户绑卡
			},
			//删除卡片
			deleteBindingRecord(e) {
				this.$confirm('是否确认删除?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.ApiRequestPost("/api/mall/ebcustomer/bank-account/delete-binding-record", {
						fAccountID: e.fAccountID,
						frv: e.frv,
						rowStatus: e.rowStatus
					}).then(
						(result) => {
							if (result.obj.status == 1) {
								this.$message.success(result.obj.message)
							} else {
								this.$message.error(result.obj.message)
							}
							this.getAcsPhysical();
						},
						(rej) => {}
					);
				}).catch(() => {
				});
			},
		},
		filters: {
			//格式化银行账户
			BankCardFormat: function(value) {
				value = value.toString();
				if (value && value.length > 8) {
					return ("<span style='letter-spacing:1.5px'>" + `${value.substring(0, 4)}` + "</span>" +
						"<span style='margin-left:5px;margin:0px 5px;letter-spacing:1.5px;font-weight:700'>" +
						`${' **** *** **** '}` + "</span>" + "<span style='letter-spacing:1.5px'>" + `${value.slice(-4)}` + "</span>");
				}
				return value
			},
			formatPhone: function(val) {
				return `${val.substring(0,3)}****${val.substring(val.length-3)}`
			}
		},
	}
</script>

<style lang="scss" scoped>
	.fonrc {
		font-weight: bold;
		color: #002bff;
		border-bottom: 1px solid #002bff;
	}

	.fonrc1 {
		font-weight: bold;
		color: #FFFF33;
	}

	.fonrc2 {
		font-weight: bold;
		color: red;
		border-bottom: 1px solid red;
	}

	.fzh {
		background-color: rgba(255, 232, 247, 1) !important;
	}

	.fonth {
		color: #FFFF33
	}

	.colrm {
		text-align: center;
		color: rgb(51, 51, 51) !important;
		margin-top: 10%;
	}

	.u665 {

		background: inherit;
		background-color: rgba(248, 249, 254, 1);
		box-sizing: border-box;
		border-width: 1px;
		border-style: solid;
		border-color: rgba(242, 242, 242, 1);

	}

	.thcls {
		// background-image: url(img/qbbg.751de4d4.png);
		// background-image: url('~@/assets/th.png');
		background-image: url(../../../assets/th.png);
		// background: linear-gradient(
		// 135deg
		// , rgba(29, 130, 254, 1) 0%, rgba(29, 130, 254, 1) 0%, rgba(80, 158, 254, 1) 52%, rgba(151, 205, 255, 1) 100%, rgba(151, 205, 255, 1) 100%);
		// background: -webkit-linear-gradient(
		// 315deg
		// , rgba(29, 130, 254, 1) 0%, rgba(29, 130, 254, 1) 0%, rgba(80, 158, 254, 1) 52%, rgba(151, 205, 255, 1) 100%, rgba(151, 205, 255, 1) 100%);
	}

	.chcls {
		// background-image: url('~@/assets/chz.png');
		background-image: url(../../../assets/chz.png);
		// background: -webkit-linear-gradient(
		// 315deg
		// , rgba(0, 196, 186, 1) 0%, rgba(0, 196, 186, 1) 0%, rgba(0, 202, 213, 1) 52%, rgba(2, 223, 218, 1) 100%, rgba(2, 223, 218, 1) 100%);
		// background: linear-gradient(
		// 135deg
		// , rgba(0, 196, 186, 1) 0%, rgba(0, 196, 186, 1) 0%, rgba(0, 202, 213, 1) 52%, rgba(2, 223, 218, 1) 100%, rgba(2, 223, 218, 1) 100%);
	}

	.spantype {
		padding-left: 2px;
		padding-right: 2px;
		border-width: 0px;
		left: 0px;
		top: 0px;
		width: 48px;
		height: 17px;
		background: inherit;
		background-color: rgba(253, 240, 198, 1);
		border: none;
		border-radius: 2px;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		font-size: 12px;
		color: #000000;
	}

	.content {

		// width: 440px;
		.title {
			height: 24px;
			line-height: 24px;
			margin-top: 8px;
			margin-left: 15px;
		}

		.top {
			height: 199px;

			.left {
				width: 160px;

				.btn-open {
					width: 160px;
					height: 40px;
					line-height: 40px;
					opacity: 1;
					background: #2e66f8;
					font-family: '阿里巴巴普惠体', '阿里巴巴普惠体'-400;
				}

				.num {
					height: 24px;
					line-height: 24px;
					opacity: 1;
				}

				.text {
					height: 17px;
					line-height: 17px;
				}
			}

			.right {
				height: 167px;
				flex: 1;

				.svgimage {
					width: 179px;
					height: 151px;
				}
			}
		}

		.bottom {

			// height: 230px;
			.card {
				width: 292px;
				height: 110px;
				opacity: 1;
				// background: #e1efff;
				overflow: hidden;
				margin-right: 15px;

				.card-top {
					// width: 405px;
					border-color: rgba(221, 235, 255, 1);
					color: #FFFFFF;
					height: 110px;
					opacity: 1;
					// background: #f8f9fe;
					box-sizing: border-box;
					padding: 15px;

					.bank {
						position: relative;
						height: 19px;
						line-height: 19px;

						.bank-icon {
							width: 34px !important;
							height: 18px !important;
						}

						.bank-name {
							margin-left: 5px;
						}
					}

					.cardID {
						height: 24px;
						margin-top: 12px;
					}
				}

				.userinfo {
					padding-left: 19px;
					padding-top: 14px;
				}

				.unbundling {
					// margin-right: 21px;
					height: 16px;
					line-height: 16px;
					position: absolute;
					right: 0;
				}

				.unbundling1 {
					// margin-right: 21px;
					height: 16px;
					line-height: 16px;
					position: absolute;
					right: 38px;
				}

			}
		}
	}

	.bgc-pink {
		height: 30px;
		background: rgba(255, 245, 223, 1);
		margin-top: 10px;
		line-height: 30px;
		padding: 0 10px;
	}

	/deep/ .el-tabs__nav-wrap::after {
		background-color: transparent;
	}
	.background-color-theme {
		background-color: #0173FE;
		color: #FFFFFF;
	}
	.search-box {
	  border: 1px solid #f2f2f2;
	  background-color: #ffffff;
	  z-index: 100;
	  max-height: 250px;
	  overflow-y: auto;
	}
	.search-item {
	  padding: 5px 10px;
	  border-bottom: 1px solid #f2f2f2;
	  color: #000000;
	  font-size: 14px;
	}
</style>
